"use client";

import { APIProvider } from "@vis.gl/react-google-maps";
import { useEffect, type FC } from "react";
import { StoreLocatorMap } from "../store-locator-map";
import type { CenterCardData } from "@repo/api-client";
import type { StoreLocatorQuery } from "@/utils/stores/use-store-locator";
import { useStoreLocator } from "@/utils/stores/use-store-locator";
import { StoreLocatorSearchFormFrame } from "../form/store-locator-search-form-frame";
import { StoreLocatorSearchForm } from "../form/store-locator-search-form-v2";
import { trackingScriptsEventsEmitter } from "@/components/tracking-scripts/utils/tracking-scripts-events-emitter";
import { reportSearch } from "@/lib/analytics";
import { StoreLocatorUtils } from "@/utils/store-locator-utils";

type Props = {
  centers: CenterCardData[]
}

export const Map: FC<Props> = function ({ centers }) {
  const setCenters = useStoreLocator(state => state.setCenters);
  const [query, setQuery] = useStoreLocator(state => [state.query, state.setQuery]);
  const [isLoading, setIsLoading] = useStoreLocator(state => [state.isLoading, state.setIsLoading]);
  const setLocation = useStoreLocator(state => state.setLocation);

  const handleQueryChange = async (
    values: StoreLocatorQuery
  ) => {
    setIsLoading(true);

    const response = await StoreLocatorUtils.fetchStoreLocatorSearchResults(
      'france',
      values
    );

    setQuery(response.query);
    setLocation(response.location);
    setCenters(response.centers);

    setIsLoading(false);
    trackingScriptsEventsEmitter.emit('onStoreLocatorSearch', {
      inRangeResultsCount: response.centers.filter(center => center.distance && query.distance ? center.distance <= query.distance : true).length,
      search: response.query.search,
      locationName: response.location.name,
      locationPath: response.location.path
    });

    reportSearch({ query: response.query.search ?? 'NULL' });
  };

  useEffect(() => {
    setCenters(centers);
  }, [centers, setCenters]);

  return <APIProvider apiKey={process.env.NEXT_PUBLIC_GMAPS_API_KEY!}>
    <div className="relative size-full">
      <StoreLocatorSearchFormFrame
        intent="white"
        className="absolute top-4 inset-x-4 max-w-lg z-10 !mt-0 flex flex-col items-center justify-between gap-2 sm:flex-row lg:gap-4">
        <StoreLocatorSearchForm
          className='flex-1'
          onChange={handleQueryChange}
          isLoading={isLoading}
          initialValues={query}
        />
      </StoreLocatorSearchFormFrame>

      <StoreLocatorMap />
    </div>
  </APIProvider>;
};